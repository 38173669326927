


















import BaseRouter from '@/components/BaseRouter.vue';
import ClientMenu from '../../components/ClientMenu.vue';
import ClientSiteDepartments from '../../components/ClientSiteDepartments.vue';
import SidebarLayout from '../../../shared/layouts/SidebarLayout.vue';
import { Client } from '@/models';
import { computed, defineComponent, onMounted, ref } from 'vue-demi';
import { routeNames, useRouter } from '@/router';

export default defineComponent({
  components: { ClientSiteDepartments, SidebarLayout, ClientMenu, BaseRouter },
  name: 'ClientsViewpage',
  setup() {
    const client = ref<Client | null>(null);

    const breadcrumbs = computed(() => {
      return [
        {
          text: 'All Clients',
          disabled: false,
          exact: true,
          to: { name: routeNames.practitioner.clients.all },
        },
        {
          text: client.value?.data.name,
          disabled: true,
          exact: true,
          to: { name: routeNames.practitioner.clients.all },
        },
      ];
    });

    const { route } = useRouter();

    const fetchClient = async (): Promise<void> => {
      client.value = await Client.findOrFail(route.value.params.uuid);
    };

    onMounted(() => fetchClient());

    return {
      client,
      breadcrumbs,
    };
  },
});
